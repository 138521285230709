import React, { Suspense, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Spinner } from './components/Spinner';
import { Error } from './components/Error';
import { AppRoot } from 'lincd-server-utils/components/AppRoot';
import { Head } from 'lincd-server-utils/components/Head';
import { Body } from 'lincd-server-utils/components/Body';
import AppRoutes, { ROUTES } from './routes';
import { ProvideAuth } from 'lincd-auth/hooks/useAuth';
import semantuLoader from '../public/images/semantu_loader2.json';
// import Loader from './components/atom/Loader';
import Lottie from '@lottielab/lottie-player/react';
// import { animationJs, init } from './components/atom/Loader-inline-js';

import style from './App.scss';
//and then the .scss.json file needs to be imported to access the class names (this file will be automatically generated)
//import our variable files last, so they overwrite any components imported so far (by routes also)
import './scss/lincd-base-variables.scss';
import './scss/global-overwrites.scss';
import './scss/theme/lincd.extension.scss';
import './scss/theme/theme.scss';
import { cl } from 'lincd/utils/ClassNames';
import { asset } from 'lincd/utils/LinkedFileStorage';

export default function App() {
  const [isLoading, setIsLoading] = useState(true);
  const contentRef = useRef(null);

  console.log("CICD Testing v34.")
  useEffect(() => {
    //when JS is loaded we turn off the loader
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);


  // disable scroll when loading is active
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isLoading]);

  return (
    <AppRoot>
      {/* <Head></Head> */}
      <Body routes={ROUTES} pageStyles={style} className={style.App}>
        <Suspense fallback={<SemantuLoader />}>
          <ErrorBoundary FallbackComponent={Error}>
            <ProvideAuth>
              <SemantuLoader loading={isLoading} />
              <AppRoutes />
            </ProvideAuth>
          </ErrorBoundary>
        </Suspense>
      </Body>
    </AppRoot>
  );
}

const SemantuLoader = ({ loading }: { loading?: boolean }) => {
  return (
    <div
      className={cl(style.loaderContainer, !loading ? style.hideLoader : '')}
    >
      {/* <Lottie
        lottie={semantuLoader}
        loop={true}
        style={{ height: 150 }}
      /> */}
      <img src={asset('/images/semantu_loader.gif')} />
    </div>
  );
};
