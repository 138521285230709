import React from 'react';
import style from './Spinner.scss';

export function Spinner({active = true}) {
  return (
    <div
      className={active && style.active}
      role="progressbar"
      aria-busy={active ? 'true' : 'false'}
    />
  );
}
