import { DefaultLayout } from '../layout/DefaultLayout';
import React from 'react';

export default function PageNotFound() {
  return (
    <DefaultLayout>
      <div>
        <h2>Page not found</h2>
      </div>
    </DefaultLayout>
  );
}
