import React, { useEffect, useState } from 'react';
import { Header } from './Header';
import style from './DefaultLayout.scss';
import { cl } from 'lincd/utils/ClassNames';
import Footer from '../components/molecules/Footer';

interface DefaultLayoutProps {
  children: React.ReactNode;
  useHeader?: boolean;
}


export function DefaultLayout({ children }: DefaultLayoutProps) {
  return <main className={style.main}>{children}</main>;
}
