//forward all storage requests to the backend
import { BackendAPIStore } from 'lincd-server/shapes/quadstores/BackendAPIStore';
import { LinkedStorage } from 'lincd/utils/LinkedStorage';
import { LinkedFileStorage } from 'lincd/utils/LinkedFileStorage';
import { getAccessUrlLocalFileStore } from 'lincd-server/utils/accessUrl';
import { LinkedErrorLogging } from 'lincd/utils/LinkedErrorLogging';
import { SentryFrontendErrorLogger } from 'lincd-sentry/utils/SentryFrontendErrorLogger';
import { getAccessURLS3FileStore } from 'lincd-s3/utils/accessUrl';
export const store = new BackendAPIStore();
LinkedStorage.setDefaultStore(store);

// set default access URL for LinkedFileStorage
// use local file for now
// TODO: later if we have the S3 setup, we can enable this
// if (process.env.NODE_ENV === 'development') {
  LinkedFileStorage.setDefaultAccessURL(getAccessUrlLocalFileStore());
// } else {
//   LinkedFileStorage.setDefaultAccessURL(getAccessURLS3FileStore());
// }

// init sentry logging before rendering the app
// LinkedErrorLogging.setDefaultLogger(new SentryFrontendErrorLogger());
